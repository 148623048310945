/*.inbox-table {*/
/*    border-collapse: separate !important;*/
/*    border-spacing: 0 !important;*/
/*}*/

.inbox-table thead {
    position: sticky;
    top: 0;
    z-index: 1;
}

.inbox-table td,
.inbox-table th {
    @apply px-4;
}