@tailwind base;
@tailwind components;
@tailwind utilities;

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
        'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
        'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}

a {
    color: dodgerblue;
}

@layer components {
    .btn-primary {
        @apply bg-primary text-primary font-semibold disabled:bg-gray-500 disabled:text-gray_400 disabled:cursor-not-allowed w-full py-2 rounded-md
    }

    .btn-text {
        @apply p-3 font-semibold text-lg
        hover:bg-hover hover:bg-opacity-hover
        disabled:cursor-not-allowed disabled:bg-opacity-0
    }

    .input {
        @apply mt-1 border-2 border-gray-300 w-full px-4 rounded
    }

    .card {
        @apply rounded-md shadow-md bg-white px-4 py-2 text-normal pt-5 px-5 pb-5
    }

    .card--primary {
        @apply bg-primary text-primary
    }

    .section-title {
        @apply text-gray
    }
}

#sidebar ul li.active a {
    position: relative;
}
#sidebar ul li.active a:before {
    @apply bg-sameAsTextPrimary;
    content: "";
    position: absolute;
    right: 12px;
    top: 50%;
    transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    width: 9px;
    height: 9px;
    border-radius: 50%;
}