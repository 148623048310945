.table {
    border-collapse: separate !important;
    border-spacing: 0 !important;
}

.table thead {
    position: sticky;
    top: 0;
    z-index: 1;
}

.table td,
.table th {
    @apply px-4 py-2;
}

.table th {
    background-color: white;
    border-top: solid 1px #ccc;
    border-left: solid 1px #ccc;
}

.table th:last-child {
    border-right: solid 1px #ccc;
}

.table tr:nth-child(odd) {
    background-color: #ccc;
}

.table tr:nth-child(even) {
    background-color: #ddd;
}

/* */

.stats-table td {
    @apply pr-2;
}
