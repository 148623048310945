main {
    width: 48rem;
    @apply mx-auto
}

h1 {
    @apply text-4xl mt-4 mb-4
}

h2 {
    @apply text-3xl mt-16 mb-4
}

img {
    @apply mt-2 mx-auto mb-16
}